function Y(e, t="", o="", n) {
    let r = setTimeout(function() {
        alert("Cannot open email link! Probably your device has no email client configured.\n\nPlease send an email to ".concat(e, " and include: ").concat(o))
    }, 2e3)
      , a = n ? "&cc=".concat(n) : "";
    window.location.href = "mailto:" + e + "?subject=" + encodeURIComponent(t) + "&body=" + encodeURIComponent(o) + a,
    window.onblur = function() {
        clearTimeout(r)
    }
}
function contact(e="") {
    let t = "xhello@ixnnovox.ro".replace(/x/g, "");
    Y(t, e);
}